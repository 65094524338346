export function uniquify(prefix = '') {
  const ms = new Date().getTime();
  // eslint-disable-next-line no-magic-numbers
  return `${prefix}${ms.toString(36)}`;
}

export const isObject = item =>
  item !== null && typeof item === 'object' && !Array.isArray(item);

export const isPlainObject = item =>
  item && item.constructor === {}.constructor;

export const isObjectOrArray = item =>
  item !== null && typeof item === 'object';
