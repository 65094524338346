import {Button, Message, Modal} from 'semantic-ui-react';
import React, {useState} from 'react';
import classNames from 'classnames';
import {isObject} from '../../utils';

const isGqlError = error =>
  Array.isArray(error.graphQLErrors) || error.networkError;

const hasMessages = messages => {
  if (!messages) return false;
  if (typeof messages === 'string') return true;
  if (isGqlError(messages)) return true;
  if (Array.isArray(messages) && messages.length) {
    return true;
  }
  return !!(isObject(messages) && Object.keys(messages).length);
};

const messagesFromGqlError = gqlErr => {
  const {graphQLErrors, networkError} = gqlErr;
  const messageList = [];
  graphQLErrors.forEach(err => messageList.push(err.message));
  if (networkError) {
    messageList.push(networkError.message);
  }

  return messageList;
};

const messageListFor = messages => {
  let messageList;
  if (isGqlError(messages)) {
    messageList = messagesFromGqlError(messages);
  } else if (Array.isArray(messages)) {
    messageList = messages.flatMap(m => messageListFor(m));
  } else if (isObject(messages) && Object.keys(messages).length) {
    messageList = Object.entries(messages)
      .filter(([key, message]) => message)
      .map(([key, message]) =>
        Array.isArray(message)
          ? message
              .map(m => (m.message ? `${key} ${m.message}` : `${key} ${m}`))
              .join(', ')
          : message,
      );
  } else {
    // error || string
    messageList = [messages.message || messages];
  }

  return messageList;
};

const MessageList = ({messages}) => {
  const messageList = messageListFor(messages);

  if (messageList.length > 1) {
    return (
      <ul>
        {messageList.map(x => (
          <li key={x}>{x}</li>
        ))}
      </ul>
    );
  }

  return <p>{messageList[0]}</p>;
};

const MessageDisplay = ({header, messages, isError}) => {
  if (!hasMessages(messages)) {
    return null;
  }

  const type = isError ? {negative: true} : {warning: true};
  return (
    <Message {...type}>
      <Message.Header>{header || 'Errors encountered'}</Message.Header>
      <MessageList messages={messages} />
    </Message>
  );
};

const MessageContainer = ({className, children, asModal}) => {
  const [open, setOpen] = useState(true);
  const handleClose = () => setOpen(false);
  if (asModal) {
    return (
      <Modal
        className={classNames(className)}
        open={open}
        size="small"
        onClose={handleClose}>
        <Modal.Content>{children}</Modal.Content>
        <Modal.Actions>
          <Button content="Ok" onClick={handleClose} />
        </Modal.Actions>
      </Modal>
    );
  }

  return <div className={classNames(className)}>{children}</div>;
};

export default ({className, errors, warnings, header, asModal}) => {
  if (!(hasMessages(errors) || hasMessages(warnings))) {
    return null;
  }

  return (
    <MessageContainer className={className} asModal={asModal}>
      <MessageDisplay
        header={header || 'Errors encountered'}
        messages={errors}
        isError
      />
      <MessageDisplay
        header={header || 'Warnings encountered'}
        messages={warnings}
      />
    </MessageContainer>
  );
};
